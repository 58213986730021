
.bgvi {
  background-color:rgb(97, 50, 205);
  color: aliceblue;
}

.even {
  background-color: yellowgreen;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}

body {
  font-family: 'Oswald', sans-serif;
}

#form-file-upload, #form-file-upload_payfit {
  margin:auto;
  height: 16rem;
  width: 28rem;
  max-width: 100%;
  text-align: center;
  position: relative;
}

#input-file-upload, #input-file-upload_payfit {
  display: none;
}

#label-file-upload, #label-file-upload_payfit  {
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #f8fafc;
}

#label-file-upload.drag-active {
  background-color: #ffffff;
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
#wrap {
  margin: 20px auto;
  text-align: center;
}
.btn-slide2 {
  position: relative;
  display: inline-block;
  height: 50px;
  width: 200px;
  line-height: 50px;
  padding: 0;
  border-radius: 50px;
  background: #fdfdfd;
  border: 2px solid #0099cc;
  margin: 10px;
  transition: .5s;
}

.btn-slide2 {
  user-select: none;
  cursor: pointer;
  border: 2px solid #efa666;
}

.btn-slide2:hover {
  background-color: #efa666;
}
.btn-slide2:hover span.circle2 {
  left: 100%;
  margin-left: -45px;
  background-color: #fdfdfd;
  color: #0099cc;
}

.btn-slide2:hover span.circle2 {
  color: #efa666;
}
.btn-slide2:hover span.title2 {
  left: 40px;
  opacity: 0;
}
.btn-slide2:hover span.title-hover2 {
  opacity: 1;
  left: 40px;
}

.btn-slide2 span.circle2 {
  display: block;
  background-color: #0099cc;
  color: #fff;
  position: absolute;
  float: left;
  margin: 5px;
  line-height: 42px;
  height: 40px;
  width: 40px;
  top: 0;
  left: 0;
  transition: .5s;
  border-radius: 50%;
}

.btn-slide2 span.circle2 {
  background-color: #efa666;
}
.btn-slide2 span.title2,
.btn-slide2 span.title-hover2 {
  position: absolute;
  left: 90px;
  text-align: center;
  margin: 0 auto;
  font-size: 16px;
  font-weight: bold;
  color: #30abd5;
  transition: .5s;
}

.btn-slide2 span.title2,
.btn-slide2 span.title-hover2 {
  color: #efa666;
  left: 80px;
}
.btn-slide2 span.title-hover2 {
  left: 80px;
  opacity: 0;
}
.btn-slide2 span.title-hover2 {
  color: #fff;
}

.credits {
  font-size: 0.6em;
  position: absolute;
  bottom: 0;
  right: 0
}
.flex{
  display: flex;
  margin: 0.5em;
}

#label-file-upload_payfit_validated{
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #cbd5e1;
  font-weight: bolder;
  background-color: #7cdec2;
  opacity: 0.8;
}

.top_right_absolute{
  position: absolute;
  top:10px;
  right:10px;
}

.flex-column{
  display:flex;
  flex-direction: column;
}
body {
  background-color: #111827;
}

#confetti-wrapper {
 overflow: hidden;
 position: absolute;
 height: 80%;
 width: 100%;
}

.confetti {
 width: 25px;
 height: 25px;
 position: absolute;
 animation: confetti 5s ease-in-out -2s infinite;
}

.confetti:nth-child(1) {
  background-image: url("../public/meghan.png");
 left: 10%;
 animation-delay: 0;
}

.confetti:nth-child(2) {
  background-image: url("../public/marie.png");
 left: 20%;
 animation-delay: -5s;
}

.confetti:nth-child(3) {
  background-image: url("../public/meghan.png");
 left: 30%;
 animation-delay: -3s;
}

.confetti:nth-child(4) {
  background-image: url("../public/marie.png");
 left: 40%;
 animation-delay: -2.5s;
}

.confetti:nth-child(5) {
  background-image: url("../public/meghan.png");
 left: 50%;
 animation-delay: -4s;
}

.confetti:nth-child(6) {
  background-image: url("../public/marie.png");
 left: 60%;
 animation-delay: -6s;
}

.confetti:nth-child(7) {
  background-image: url("../public/meghan.png");
 left: 70%;
 animation-delay: -1.5s;
}

.confetti:nth-child(8) {
  background-image: url("../public/marie.png");
 left: 80%;
 animation-delay: -2s;
}

.confetti:nth-child(9) {
  background-image: url("../public/meghan.png");
 left: 90%;
 animation-delay: -3.5s;
}

.confetti:nth-child(10) {
  background-image: url("../public/marie.png");
 left: 100%;
 animation-delay: -2.5s;
}

.confetti:nth-child(11) {
  background-image: url("../public/meghan.png");
 left: 72%;
 animation-delay: -5.5s;
}

.confetti:nth-child(12) {
  background-image: url("../public/marie.png");
 left: 34%;
 animation-delay: -2.8s;
}

.confetti:nth-child(13) {
  background-image: url("../public/meghan.png");
 left: 100%;
 animation-delay: -7.5s;
}

.confetti:nth-child(14) {
  background-image: url("../public/marie.png");
 left: 16%;
 animation-delay: -8.5s;
}

@keyframes confetti {
 0% {
   transform: rotateZ(15deg) rotateY(0deg) translate(0, 0);
 }
 25% {
   transform: rotateZ(5deg) rotateY(360deg) translate(-5vw, 20vh);
 }
 50% {
   transform: rotateZ(15deg) rotateY(720deg) translate(5vw, 60vh);
 }
 75% {
   transform: rotateZ(5deg) rotateY(1080deg) translate(-10vw, 80vh);
 }
 100% {
   transform: rotateZ(15deg) rotateY(1440deg) translate(10vw, 110vh);
 }
}

@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,300,800);

  /*========= PAGE LAYOUT =========*/
  * {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    padding: 0;
    margin: 0;
    list-style-type: none;
  }

  body{
    background-color: white;
    font-family: 'Open Sans', Helvetica, Verdana, sans-serif;
    color: #222;
    font-size: 16px;
    width: 100%;
    height: 100%;
  }

  a{
    text-decoration: none;
    color: inherit;
    font-weight: 300;
  }

  h1, h2, h3{text-transform: uppercase}

  #pagewrap{
    max-width: 100%;
    margin: 0 auto;
  }

  /* main nav section */
  .main-nav{
    background-color: white;
    z-index: 1;
    overflow: hidden;
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
  }

  .main-nav h1 {
    margin-left: 2%;
    text-transform: uppercase;
  }

.main-nav h1 a {
  padding: 20px 0;
  display: block;
  font-weight: 800;
  width: 20%;
}

  .main-nav ul{
    white-space: nowrap;
    display: flex;
  }

  .main-nav ul li{
    display: inline-block;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    flex: 1 1 auto;
  }

  .main-nav ul li a{
    padding: 1em;
    display: inline-block;
  }
  /* end of main nav section */
/* main header section */
.main-header{
  width: 100%;
  padding: 20vh;
  background-color: #222;
}

.header-content{
  background-color: white;
  margin: 0 auto;
  text-align: center;
  max-width: 450px;
  width: 100%;
  height: 100%;
  padding: 40px;
  border: 10px solid #f2f0f0;
  line-height: 2.5em;
  position: relative;
  top: -50px;
}

.header-content h1, .header-content h3{
  font-weight: normal;
  letter-spacing: 0.2em;
  text-transform: uppercase;
}

.header-content h1{
  font-size: 1.5em;
}
.header-content h3{
  font-size: .90em;
}
/* end of main header section */
.active {
  color: rgb(94, 18, 161);
  font-weight: bolder;
}
